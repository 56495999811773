import logo from "../images/casa_di_pietra_logo.webp";
import bed1 from "../images/suite_interna_1.webp";
import bed2 from "../images/suite_interna_2.webp";
import bed3 from "../images/suite_interna_3.webp";
import bed4 from "../images/suite_interna_4.webp";
import bed5 from "../images/suite_externa_5.webp";
import pic1 from "../images/hospedagem/pic1.webp";
import pic2 from "../images/hospedagem/pic2.webp";
import pic3 from "../images/hospedagem/pic3.webp";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel";

const slide_images = [
  {
    image: bed1,
    title: "SUÍTE MASTER",
    description: ["1 cama de casal tamanho King"],
  },
  {
    image: bed2,
    title: "SUÍTE INTERNA 2",
    description: ["1 cama de casal tamanho King + TV"],
  },
  {
    image: bed3,
    title: "SUÍTE INTERNA 3",
    description: ["1 cama de casal tamanho Queen + TV"],
  },
  {
    image: bed4,
    title: "SUÍTE INTERNA 4",
    description: ["2 camas de solteiro uma delas com bicama e TV"],
  },
  {
    image: bed5,
    title: "SUITE EXTERNA 5",
    description: ["2 camas de solteiro uma delas com bicama e TV"],
  },
];

const slide_properties = {
  prevArrow: (
    <button className="border-0 bg-transparent">
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
        <path d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z" />
      </svg>
    </button>
  ),
  nextArrow: (
    <button className="border-0 bg-transparent fw-bold">
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
        <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
      </svg>
    </button>
  ),
};

const images = [pic2, pic1, pic3];

export default function Hospedagem() {
  return (
    <>
      <div className="col-12 col-lg-8 m-auto py-4">
        <section className="d-flex flex-column justify-content-between align-items-center flex-wrap pb-4">
          <div className="mx-auto d-flex align-items-center flex-column">
            <img src={logo} alt="logo" className="col-3" />
            <div className="d-flex justify-content-center border-bottom border-1 font-regular spaced text-center border-green">
              <span className="pb-2 fs-small">
                <a href="../#history" className="text-decoration-none text-inherit mx-1">
                  HISTÓRIA
                </a>{" "}
                |{" "}
                <a href="../#experiences" className="text-decoration-none text-inherit mx-1">
                  EXPERIÊNCIAS
                </a>{" "}
                |{" "}
                <a href="../#suites" className="text-decoration-none text-inherit mx-1">
                  SUÍTES
                </a>{" "}
                |{" "}
                <a href="../  #social" className="text-decoration-none text-inherit mx-1">
                  CONTATOS
                </a>
              </span>
            </div>
          </div>
        </section>
        <section className="py-4 my-4">
          <div style={{ width: "fit-content" }} className="ms-2 ms-lg-0">
            <span className="m-0 fs-4 font-regular ps-1">nossa</span>
            <h1 className="mb-4 redwood-hollow fs-big-title pt-1" style={{ opacity: "0.4", lineHeight: "0.7" }}>
              HOSPEDAGEM
            </h1>
          </div>
          <div className="w-100 gap-4 align-items-center p-4 mb-4" style={{ backgroundColor: "rgb(116, 124, 111)" }}>
            <p className="fs-6 font-bold mb-0 text-white">COMO FUNCIONA?</p>
            <p className="fs-6 font-regular mb-3 text-white">
              Trabalhamos com locação da casa toda. Nossa Vila possui 5 suítes e acomoda até 12 hóspedes.
            </p>
            <p className="fs-6 font-bold mb-0 text-white">POSSO ME HOSPEDAR POR APENAS 1 NOITE?</p>
            <p className="fs-6 font-regular text-white">
              Possuímos o mínimo de 2 noites aos fins de semana. Já em feriados, o período mínimo são 3 noites.
            </p>
          </div>
        </section>
        <section className="py-4 my-4">
          <div style={{ width: "fit-content" }} className="mx-auto">
            <span className="m-0 fs-4 font-regular ps-1">as nossas</span>
            <h1 className="redwood-hollow fs-big-title pt-1" style={{ opacity: "0.4", lineHeight: "0.7" }}>
              SUÍTES
            </h1>
          </div>
          <Carousel images={slide_images} properties={slide_properties} numSlides={1} />
        </section>
        <section className="w-100 pt-4 mt-4 px-4 px-lg-0">
          <div style={{ width: "fit-content" }}>
            <span className="m-0 fs-4 font-regular ps-1">serviços</span>
            <h1 className="redwood-hollow fs-big-title pt-1" style={{ opacity: "0.4", lineHeight: "0.7" }}>
              DISPONÍVEIS
            </h1>
          </div>
          <p className="fs-6 font-regular">
            Temos o serviço de cozinheira, arrumadeira e zeladoria, cujo pagamento deverá ser efetuado diretamente aos mesmos. Enviamos aos hóspedes
            um cardápio sugestivo e elaboramos as compras. O valor do mercado é por conta do locatário.
          </p>
          <p className="fs-6 font-regular">Oferecemos também sugestões de passeios pela região e prestamos todo o apoio durante a sua hospedagem.</p>
          <div className="row w-100 m-0">
            {images.map((image, index) => (
              <img key={index} className="col-4 p-0 m-0" src={image} alt="smth" />
            ))}
          </div>
          <p className="fs-6 font-regular mt-4">
            Os ambientes, todos muito bem decorados, convidam para momentos únicos, como a sala de estar, a sala de lareira, a sala de jantar, uma
            saleta envidraçada com vista panorâmica, escritório com TV a cabo e Netflix, cozinha totalmente equipada e 5 confortáveis suítes.
          </p>
          <p className="fs-6 font-regular">
            A área de lazer é composta pelo espaço gourmet com churrasqueira, forno de pizza, piscina aquecida, sauna, quadra de tênis, rede e
            raquetes de badmington.
          </p>
        </section>
        <section className="w-100 pt-4 mt-4 px-4 px-lg-0">
          <div style={{ width: "fit-content" }}>
            <span className="m-0 fs-4 font-regular ps-1">serviços</span>
            <h1 className="redwood-hollow fs-big-title pt-1" style={{ opacity: "0.4", lineHeight: "0.7" }}>
              OPCIONAIS
            </h1>
          </div>
          <p className="fs-6 font-regular">
            <p className="font-italic">Consultar valores e disponibilidade</p>
            <ul>
              <li>Noite de Pizza</li>
              <li>Massagem</li>
              <li>Aula de Ioga</li>
              <li>Música ao vivo</li>
              <li>Recreação e Serviço de Babá</li>
            </ul>
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
}
