import useScreenWidth from "../hooks/useScreenWidth";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

type CarouselProps = {
  images: {
    image: string;
    title: string;
    description: string[];
  }[];
  properties: {
    prevArrow: JSX.Element;
    nextArrow: JSX.Element;
  };
  numSlides?: number;
  topSpace?: boolean;
};

export default function Carousel({ images, properties, numSlides, topSpace }: CarouselProps) {
  const screenWidth = useScreenWidth();
  const numOfSlides = numSlides ? numSlides : screenWidth > 992 ? 3 : 1;
  return (
    <div className={`${topSpace ? "my-5 py-5" : ""} text-center`}>
      <Slide slidesToScroll={1} slidesToShow={numOfSlides} autoplay={false} indicators {...properties}>
        {images.map((object, index) => (
          <div key={index} className="slide-image d-flex flex-column h-100 mx-5">
            <div style={{ height: "300px", backgroundImage: `url(${object.image})`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
            <div className="bg-green d-flex flex-column align-items-center justify-content-center py-4 flex-grow-1">
              <span className="text-light fs-4 fw-bold mb-2">{object.title}</span>
              {object.description.map((description: string, index: number) => (
                <span key={index} className="text-light col-10">
                  {description}
                </span>
              ))}
            </div>
          </div>
        ))}
      </Slide>
      <div className="mx-4 mx-lg-0 mt-4">
        <span className="text-center">Precisa de Berço para o seu bebê? Disponibilizamos o Berço de viagem (solicitar na reserva)</span>
      </div>
    </div>
  );
}
