import logo from "../images/casa_di_pietra_logo.webp";
import copos from "../images/eventos/copos.webp";
import comida from "../images/eventos/comida.webp";
import pic1 from "../images/eventos/slides/pic1.webp";
import pic2 from "../images/eventos/slides/pic2.webp";
import pic3 from "../images/eventos/slides/pic3.webp";
import pic4 from "../images/eventos/slides/pic4.webp";
import pic5 from "../images/eventos/slides/pic5.webp";
import pic6 from "../images/eventos/slides/pic6.webp";
import { Slide } from "react-slideshow-image";
import Footer from "../components/Footer";

const images = [pic1, pic2, pic3, pic4, pic5, pic6];

const properties = {
  prevArrow: (
    <button className="border-0 bg-transparent">
      <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path
          fill="#ffffff"
          d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
        />
      </svg>
    </button>
  ),
  nextArrow: (
    <button className="border-0 bg-transparent fw-bold">
      <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path
          fill="#ffffff"
          d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
        />
      </svg>
    </button>
  ),
};

export default function Eventos() {
  return (
    <>
      <div className="col-12 col-lg-8 m-auto py-4">
        <section className="d-flex flex-column justify-content-between align-items-center flex-wrap pb-4">
          <div className="mx-auto d-flex align-items-center flex-column">
            <img src={logo} alt="logo" className="col-3" />
            <div className="d-flex justify-content-center border-bottom border-1 font-regular spaced text-center border-green">
              <span className="pb-2 fs-small">
                <a href="../#history" className="text-decoration-none text-inherit mx-1">
                  HISTÓRIA
                </a>{" "}
                |{" "}
                <a href="../#experiences" className="text-decoration-none text-inherit mx-1">
                  EXPERIÊNCIAS
                </a>{" "}
                |{" "}
                <a href="../#suites" className="text-decoration-none text-inherit mx-1">
                  SUÍTES
                </a>{" "}
                |{" "}
                <a href="../  #social" className="text-decoration-none text-inherit mx-1">
                  CONTATOS
                </a>
              </span>
            </div>
          </div>
        </section>
        <section className="py-4 my-4">
          <div style={{ width: "fit-content" }} className="ms-2 ms-lg-0">
            <span className="m-0 fs-4 font-regular ps-1">nossos</span>
            <h1 className="mb-4 redwood-hollow fs-big-title pt-1" style={{ opacity: "0.4", lineHeight: "0.7" }}>
              EVENTOS
            </h1>
          </div>
          <p className="fs-5 mb-4 font-regular col-9 mx-auto">
            A Casa Di Pietra - Villa Toscana é o cenário perfeito para transformar seu evento em uma experiência inesquecível.
          </p>
          <div className="w-100 d-lg-flex gap-4 align-items-center pb-4 mb-4">
            <div className="w-img-2 d-flex justify-content-center">
              <img src={copos} alt="copos" className="col-12" loading="lazy" />
            </div>
            <div className="col-9 col-lg-6 my-2 d-flex flex-column justify-content-center m-auto mt-4">
              <p className="fs-5 font-regular">
                Localizada em meio à deslumbrante paisagem de Serra Negra, nossa casa oferece um ambiente único e sofisticado, ideal para diversos
                eventos especiais!
              </p>
            </div>
          </div>
          <div className="w-100 d-lg-flex gap-4 align-items-center pt-4 mt-4">
            <div className="w-img-2 d-lg-flex justify-content-center flex-end" style={{ order: "2" }}>
              <img src={comida} alt="comida" className="col-12" loading="lazy" />
            </div>
            <div className="col-9 col-lg-6 my-2 d-flex flex-column justify-content-center mx-auto my-2 mt-4">
              <p className="fs-5 font-regular">
                Seja um aniversário, um workshop corporativo, ou aquela festa de final de ano da empresa, a Casa Di Pietra proporciona o espaço e o
                conforto que seu evento merece. Cada detalhe foi cuidadosamente pensado para garantir que você e seus convidados possam desfrutar de
                momentos memoráveis, desconectando da rotina e respirando os ares tranquilos da serra.
              </p>
              <p className="fs-5 font-regular">
                Nosso espaço versátil é capaz de acomodar os mais diversos tipos de eventos, sempre com a elegância e a exclusividade que só a Casa Di
                Pietra pode oferecer.
              </p>
            </div>
          </div>
        </section>
        <section className="w-100 pt-4 mt-4">
          <Slide slidesToScroll={1} slidesToShow={1} autoplay={false} indicators {...properties}>
            {images.map((image) => (
              <div className="col-12" style={{ maxHeight: "75vh" }}>
                <img src={image} alt="slide" className="col-12" loading="lazy" />
              </div>
            ))}
          </Slide>
        </section>
      </div>
      <Footer />
    </>
  );
}
