import sunset_couple from "../images/sunset_couple.webp";
import plates from "../images/plates.webp";
import ensaios from "../images/ensaios.webp";
import hospedagem from "../images/hospedagem.webp";

export default function Experiences() {
  return (
    <section id="experiences" className="main-div2 pb-5">
      <div className="m-auto" style={{ width: "fit-content" }}>
        <span className="m-0 fs-4 font-regular">nossas</span>
        <h1 className="mb-4 redwood-hollow fs-big-title" style={{ opacity: "0.4", lineHeight: "0.7" }}>
          EXPERIÊNCIAS
        </h1>
      </div>
      <div className="w-100 d-lg-flex flex-wrap">
        <a href="/wedding" className="position-relative col-12 col-lg-6">
          <img src={sunset_couple} alt="sunset_couple" className="w-100 h-100" loading="lazy" />
          <h2 className="position-absolute m-0 fs-1 extra text-white redwood-hollow">WEDDING</h2>
        </a>
        <a href="/eventos" className="position-relative col-6">
          <img src={plates} alt="plates" className="w-100" loading="lazy" />
          <h2 className="position-absolute m-0 fs-1 extra text-white redwood-hollow">EVENTOS</h2>
        </a>
        <a href="/ensaios-fotograficos" className="position-relative col-6">
          <img src={ensaios} alt="ensions_fotograficos" className="w-100" loading="lazy" />
          <h2 className="position-absolute m-0 fs-1 extra text-white redwood-hollow">ENSAIOS</h2>
        </a>
        <a href="/hospedagem" className="position-relative col-6">
          <img src={hospedagem} alt="hospedagem" className="w-100" loading="lazy" />
          <h2 className="position-absolute m-0 fs-1 extra text-white redwood-hollow">HOSPEDAGEM</h2>
        </a>
      </div>
      <div className="d-flex flex-column align-items-center gap-5">
        <span className="col-7 mt-5 pt-5 text-center fs-text-4 font-regular">
          A Casa Di Pietra está disponível para hospedagem aos finais de semana, férias e feriados, oferecerendo um ambiente acolhedor e bem
          localizado para garantir uma experiência relaxante e memorável aos nossos hóspedes durante esses períodos.
        </span>
        <div className="my-border w-25"></div>
      </div>
    </section>
  );
}
